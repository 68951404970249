/* Footer Container */
.footer {
  background-color: black;
  padding: 20px 0;
  color: white;
  text-align: center; /* Center-align text */
}

/* Top Divider Line */
.footer-divider {
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next line on smaller screens */
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-left: 10%; /* Adjust the left margin for smaller screens */
  margin-right: 10%; /* Adjust the right margin for smaller screens */
}

/* Social Icons */
.footer-social-icons {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Add space between icons and buttons on smaller screens */
}

.footer-social-icons a {
  margin-right: 20px; /* Increase margin for larger icons */
  font-size: 24px; /* Reduce font size for smaller screens */
  color: white; /* Set icon color to white */
  text-decoration: none;
}

/* App Buttons */
.footer-app-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically on smaller screens */
  align-items: center;
  margin-top: 20px; /* Add more space between icons and buttons on smaller screens */
}

.footer-app-buttons button, .footer-app-buttons img {
  margin: 1px 0; /* Add space between buttons */
  padding: 0px 0px; /* Increase button padding for a more spacious feel */
  border: none; /* Remove the button border */
  border-radius: 5px; /* Add rounded corners */
  background-color: #000; /* Set a black background color */
  color: #fff; /* Set text color to white for contrast */
  font-size: 16px; /* Increase font size for better readability */
  cursor: pointer; /* Add a pointer cursor for interactivity */
  transition: transform 0.2s; /* Add smooth transition effect for scaling */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-app-buttons button:hover, .footer-app-buttons img:hover {
  transform: scale(1.05); /* Add a slight scaling effect on hover for a modern touch */
}




/* Links Section */
.footer-links {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  border-top: 1px solid #635959;
  margin-left: 10%; 
  margin-right: 10%; 
}

/* Left Side Links */
.footer-links-left {
  flex: 1; /* Occupy available space equally */
}

.footer-links-left a {
  display: block;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer-links-left a:hover {
  text-decoration: underline;
}

/* Company Logo */
.footer-company-logo {
  flex: 1; /* Occupy available space equally */
  text-align: center;
  margin-top: 10px; /* Add margin to separate from links */
}

.footer-company-logo img {
  max-width: 30%; /* Ensure the image does not exceed its container width */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer;
  border: 0px solid white; /* Add a border to contain the image within the border */
  border-radius: 5px;
}

/* Bottom Divider Line */
.footer-bottom p {
  font-size: 12px; /* Reduce font size for smaller screens */
}

@media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    .footer-divider {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-social-icons {
      flex-direction: row; /* Center the icons horizontally */
      justify-content: center; /* Center the icons horizontally */
    }
  
    .footer-app-buttons {
      align-items: center; /* Center the buttons horizontally */
    }
    
  .footer-links {
    flex-direction: column;
  }

  .footer-links-left {
    flex: none; /* Remove flex to let the links stack in a column */
  }

  .footer-company-logo {
    margin-top: 10px; /* Add margin to separate from links */
  }
}
