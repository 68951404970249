.applications-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background: url('../public/banner-collection-anchita.png') no-repeat center center;
    background-size: cover;
    color: #fff;
    text-align: center;
  }
  
  .applications-section h2 {
    font-size: 2rem;
  }
  
  .applications-section p {
    font-size: 1.2rem;
  }
  