.apps-icons-container {
  display: flex;
  flex-direction: column;
}

.apps-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  cursor: pointer;
  flex: 1;
  min-width: 250px;
}

.app img {
  width: 100%; /* Make the image take up the full width of its container */
  max-width: 100%; /* Limit the image's maximum width */
  height: auto; /* Allow the image's height to adjust proportionally */
  max-height: 100%; /* Limit the image's maximum height */
}

.app-details {
  text-align: center;
  margin-top: 10px;
}

h3 {
  font-size: 18px;
  margin: 0;
}

p {
  font-size: 14px;
  margin: 0;
}
