/* Styling for the About Us section */
.about-section {
    background-color: #f5f5f5;
    padding: 30px 10px; /* Increase the horizontal padding for space on the top and bottom and adjust left and right sides */
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative; /* Make it a relative container */
}

.about-section h2 {
    font-size: 28px;
    color: #333;
    margin: 0; /* Remove default margin */
}

.about-section p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    margin: 20px 0;
    text-align: left;
    padding: 0 5%; /* Use percentage for padding to adapt to different screen widths */
}

/* Styling for the culture links */
.culture-links {
    margin: 20px 0;
    text-align: center;
}

.culture-links h3 {
    font-size: 20px;
    color: #333;
    margin: 0;
    text-align: center; /* Center align the text */
}

.culture-links ul {
    list-style: none;
    padding: 0;
}

.culture-links li {
    margin: 5px 0;
}

.culture-links a {
    text-decoration: none;
    color: #007BFF;
    font-weight: bold;
}

.about-section .culture-links + p {
    margin: 20px 0;
    text-align: left;
}

/* Update your CSS to apply padding to the common container */
.about-section {
    background-color: #f5f5f5;
    padding: 30px;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative; /* Make it a relative container */
}

/* Rest of your existing styles... */

/* Responsive design - adjust as needed */
@media (max-width: 768px) {
    .about-section {
        padding: 30px;
    }
    .about-section h2 {
        font-size: 24px;
    }
    .about-section p {
        font-size: 14px;
        padding: 0 30px; /* Set the padding to match the larger screens */
    }
    
    /* Add styles to make culture links mobile-responsive */
    .culture-links {
        text-align: left; /* Adjust text alignment */
    }
    .culture-links h3 {
        font-size: 18px; /* Adjust font size */
    }
    .culture-links li {
        margin: 5px 0;
    }
    .culture-links a {
        font-size: 14px; /* Adjust font size */
    }
}

/* Add a media query for screens between 769px and 1199px (adjust as needed) */
@media (min-width: 769px) and (max-width: 1199px) {
    .about-section {
        padding: 30px;
    }
    .about-section h2 {
        font-size: 28px;
    }
    .about-section p {
        font-size: 16px;
        padding: 0 15%; /* Adjust padding for screens within this range */
    }

    /* Add styles for culture links within this range */
    .culture-links {
        text-align: center; /* Center align the text */
    }
    .culture-links h3 {
        font-size: 20px;
    }
    .culture-links li {
        margin: 5px 0;
    }
    .culture-links a {
        font-size: 16px; /* Adjust font size */
    }
}

/* Additional media query for larger screens, adjust as needed */
@media (min-width: 1200px) {
    .about-section {
        padding: 30px;
    }
    .about-section h2 {
        font-size: 32px;
    }
    .about-section p {
        padding: 0 30%; /* Adjust padding for larger screens */
    }
}
