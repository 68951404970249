
.support-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background: #042038; /* Use the same blue color as in the "Applications" section */
    color: #fff;
    text-align: center;
  }
  
  .support-section h2 {
    font-size: 2rem;
  }
  
  .support-section p {
    font-size: 1.2rem;
    padding-bottom: 30px;
  }
  
  .rounded-button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 20px; /* Rounded edges */
    background-color: #ffca28;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .rounded-button:hover {
    background-color: #ff9900;
  }
  